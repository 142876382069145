import { Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { filter, fromEvent, map, skip, Subject } from 'rxjs';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';

@Injectable({ providedIn: 'root' })
export class SidebarService {
  private readonly LOCAL_STORAGE_KEY = 'sidebar-is-collapsed';
  private readonly sidebarCollapsed = signal(false);

  private readonly shortcut$ = fromEvent(document, 'keydown').pipe(
    map(event => event as KeyboardEvent),
    filter(event => event.ctrlKey && event.key === 'b')
  );

  sidebarCollapsed$ = toObservable(this.sidebarCollapsed);
  sidebarChanged$ = new Subject<void>();

  private activeRoute = signal<string>('');
  routeChanged = toObservable(this.activeRoute);

  constructor(private breakpointObserver: BreakpointObserver) {
    const isCollapsed =
      sessionStorage.getItem(this.LOCAL_STORAGE_KEY) ?? 'true';
    const value = JSON.parse(isCollapsed);
    this.sidebarCollapsed.set(value);

    this.breakpointObserver
      .observe([
        Breakpoints.HandsetLandscape,
        Breakpoints.HandsetPortrait,
        Breakpoints.TabletPortrait,
      ])
      // Should skip first, because otherwise it will trigger directly and restoring of stored value will not work
      .pipe(skip(1))
      .subscribe(state => this.handleBreakpointEvent(state));

    this.shortcut$.subscribe(() => this.handleShortcut());
  }

  public open(): void {
    this.toggleSidebar(false);
  }

  public collapse(): void {
    this.toggleSidebar(true);
  }

  private toggleSidebar(collapsed: boolean): void {
    this.sidebarCollapsed.set(collapsed);
    this.sidebarChanged$.next();
    this.setValueInLocalStorage();
  }

  private handleBreakpointEvent(state: BreakpointState) {
    if (state.matches) {
      this.sidebarCollapsed.set(true);
    } else {
      this.sidebarCollapsed.set(false);
    }
    this.sidebarChanged$.next();
  }

  private setValueInLocalStorage(): void {
    sessionStorage.setItem(
      this.LOCAL_STORAGE_KEY,
      JSON.stringify(this.sidebarCollapsed())
    );
  }

  setActiveRoute(route: string) {
    this.activeRoute.set(route);
  }

  private handleShortcut(): void {
    this.toggleSidebar(!this.sidebarCollapsed());
  }
}
